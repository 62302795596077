import React, {useEffect, useState} from 'react';
import {Button, List, Tooltip} from 'antd';// adjust the path as necessary
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {useData} from "../../DataContext";
import routesList from "./../../routes";
import {getNotificationDateFromTimeStamp} from "../../utils/common";
import PermissionChecker from "../../PermissionChecker";
import http from "../../http";

// Example user permission fetch function
const getUserPermissions = (storedData) => {
    return storedData?.permitted_urls || window.sessionStorage.getItem('permissions') || [];
}

const NotificationContent = (props) => {
    const {sharedData} = useData();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState([])

    useEffect(() => {
        if (props.notifications) {
            setNotifications(props.notifications);
        }
    }, [props.notifications]);

    const checkPermission = (redirectUrl) => {
        const route = routesList.find((r) => r.path === redirectUrl);
        const userPermissions = getUserPermissions(sharedData);
        return route ? route.requiredPermission?.some((perm) => userPermissions.includes(perm)) : false;
    };

    const handleNotificationClick = (notification) => {
        navigate(notification.redirect_url, { state: notification.stateData });
    };

    const handleClearNotification=(notification)=>{
        http.patch(`/api/v1/notifications/${notification.id}/mark_read/`).then((resp)=>{
            if(resp.status === 200) {
                let updatedNotifications = notifications.filter((value) => value.id !== notification.id);
                setNotifications(updatedNotifications)
            }
        })
    }

    return (
        <div style={{ width:'350px', maxHeight: '300px', overflowY: 'auto', overflowX:'hidden'}}>
            <List
                dataSource={notifications}
                size="small"
                renderItem={(notification) => {
                    const hasPermission = checkPermission(notification.redirect_url);
                    const key = Object.keys(notification?.stateData)[0]
                    const messageData = notification?.stateData[key];
                    if (!hasPermission) return null;
                    return (
                        <List.Item onClick={() => handleNotificationClick(notification)} style={{ padding: '8px 16px', cursor: 'pointer' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                {/* Red Bullet Point */}
                                <div style={{
                                    width: '8px',
                                    height: '8px',
                                    backgroundColor: '#DC000C',
                                    borderRadius: '50%',
                                    marginRight: '12px',
                                    flexShrink: 0
                                }}></div>

                                {/* Title and Date */}
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    {/* Title */}
                                    <Tooltip title={t(notification.title, {...messageData})}>
                                        <span style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            marginRight: '12px',
                                            flexGrow: 1
                                        }}>
                                            {t(notification.title, {...messageData})}
                                        </span>
                                    </Tooltip>
                                    {/* Date */}
                                    <span style={{ fontSize: '12px', color: '#888', flexShrink: 0 }}>
                                        {getNotificationDateFromTimeStamp(notification.created)}
                                    </span>
                                    <PermissionChecker requiredPermissions={['notification-mark-read']}>
                                        <Button
                                            type="text"
                                            onClick={() => handleClearNotification(notification)}
                                            style={{ marginLeft: '8px', color: '#888' }}
                                        >
                                            ×
                                        </Button>
                                    </PermissionChecker>
                                </div>
                            </div>
                            {/*<p style={{ fontSize: '12px', color: '#888' }}>{t(notification.message, {...messageData})}</p>*/}
                        </List.Item>
                    );
                }}
            />
        </div>
    );
};

export default NotificationContent;
