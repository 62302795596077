import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Col, Row, Tooltip} from "antd";


const UserMachineListPage = (props) => {
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();


  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (data)=>{
    return null;
  }

    const buildCardBody = (data)=>{
        const cardBodyFields = {'number': t('machine_carnumber_field'), 'machine_type_text':  t('machine_type_field')};
        if(data.has_running_contract){
            cardBodyFields['running_contract_start'] = t('machine_list_rental_start_date_label')
            cardBodyFields['running_contract_end'] = t('machine_list_rental_end_date_label')
        }
        let innerContents = [];
        const rightColumnContent = (
            <Row style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Tooltip title={`${t('last_edit_label')}: ${data.updated_by_text}`}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {t('last_edit_label')}: {data.updated_by_text}
                    </span>
                </Tooltip>
            </Row>
        );
        if(cardBodyFields) {
            for (const key in cardBodyFields) {
                const nestedKeys = key.split(".");
                let value = data;
                for (const nestedKey of nestedKeys) {
                    value = value[nestedKey];
                    if (value === undefined) {
                        break;
                    }
                }
                if( key === 'machine_type_text'){
                    innerContents.push(<Row key={key} style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <Tooltip title={`${cardBodyFields[key]}: ${t(value)}`}>
                                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {cardBodyFields[key]}: {t(value)}
                                </span>
                            </Tooltip>
                        </Row>)
                }
                else{
                    innerContents.push(
                        <Row key={key} style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <Tooltip title={`${cardBodyFields[key]}: ${t(value)}`}>
                                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {value ? `${cardBodyFields[key]}: ${value}` : (
                                        <span style={{ visibility: 'hidden' }}>
                                            {cardBodyFields[key]}: placeholder
                                        </span>
                                    )}
                                </span>
                            </Tooltip>
                        </Row>
                    )
                }
            }
        }
        return <Row style={{ display: 'flex', width: '100%' }} key={'cardContent'}>
            <Col span={12} style={{ paddingRight: '8px' }}>
                <Row style={{ flexDirection: 'column', justifyContent: 'start' }}>
                    {innerContents}
                </Row>
            </Col>
            <Col span={12} style={{ paddingLeft: '8px' }}>
                <Row style={{ flexDirection: 'column', justifyContent: 'start' }}>
                    {rightColumnContent}
                </Row>
            </Col>
        </Row>
    }

  const buildCardFooter=(data)=>{
    return null;
  }

  const renderFooterFields=(data)=>{
        if(data.license_status !== 2){
            return [<label style={{color:'#377EF3'}}>{t('machine_registration_pending_status_text')}</label>]
        }
        return [<label style={{color:data.lease_status ===1 ? '#CE7F00': '#079327'}}>{t(`${data.lease_status_text}`)}</label>]
    }

    const buildFooterFields=(data)=>{
        return renderFooterFields(data);
    }

  const config = {
    pageSize: props.pageSize || 10,
    url: `/api/v1/machines/?assigned=${props.user.id}`,
    pageTitle: t('user_machine_listpage_title'),
    pageSubtitle: t('user_machine_listpage_subtitle'),
    deleteTitle: t('machine_delete_confirmation_text'),
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'name',
    cardDetailButtonText: t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    cardBodyFields: {
      'number': t('machine_carnumber_field'),
      'machine_type_text': t('machine_type_field'),
      'year_made': t('machine_yearmade_field'),
      'manufacture': t('machine_manufacturer_field')
    },
    cardFooterFields: [],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
    hideAddButton: true,
    hideEdit: true,
    disableDelete: true,
    viewPermission: ['machine-detail'],
  }

  const handleDetailClick=(item)=>{
    navigate('/machine/detail/', { replace: false, state: {machine: item} })
  }

  return (
      <>
        <ListPage
            config={config}
            onDetailClick={(item)=>handleDetailClick(item)}
            reload={reload}
        />
      </>
  );
};

export default UserMachineListPage;
