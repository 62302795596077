import {
    BranchesOutlined,
    SettingOutlined,
    CloudServerOutlined,
    AppstoreOutlined,
    ApiOutlined,
    PicLeftOutlined,
    AuditOutlined,
    GithubFilled,
} from '@ant-design/icons';
import { Menu } from 'antd';
import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import classes from './Menu.module.css';
import MachineIcon from "../CustomIcons/MachineIcon";
import StaffIcon from "../CustomIcons/StaffIcon";
import ConstructorIcon from "../CustomIcons/MachineUserIcon";
import ProfileIcon from "../CustomIcons/ProfileIcon";
import TerminalDeviceIcon from "../CustomIcons/TerminalDeviceIcon";
import VRSServerIcon from "../CustomIcons/VRSServerIcon";
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";
import {useData} from "../../DataContext";

// const { SubMenu } = Menu;

// submenu keys of first level
const pathNameKeyMapper = {
    '': 'profile',
    '/': 'profile',
    '/user/profile': 'profile',
    '/constructors': 'constructor',
    '/past_constructors': 'past-constructor',
    '/constructor/detail': 'constructor',
    '/machine/detail': 'machine',
    '/machines': 'machine',
    '/vrs_servers': 'vrs',
    '/vrs_server/detail': 'vrs',
    '/users': 'user',
    '/user/detail': 'user',
    '/branches': 'branch-settings',
    '/terminal_devices': 'terminal-devices',
    '/settings': 'general-settings',
    '/user_permissions': 'user-permissions',
    '/auth/backups': 'backup-restore',
    '/system_infos': 'system-infos',
    '/terminal_applications': 'terminal-applications',
    '/auth_apps': 'auth-apps',
    '/api_servers': 'api-servers',
    '/api_events': 'api-events',
    '/api_tasks': 'api-tasks',
    '/bucket_tool_configs': 'bucket-tool-configs'
};
const getAdminMenu = (props, t) => {
    return [
        <Menu.Item key="profile" icon={<ProfileIcon/>} className={classes.menuItemStyle}>
            <Link to='/user/profile'>{t('menu_0')}</Link>
        </Menu.Item>,
        <Menu.Item key="constructor" icon={<ConstructorIcon />} className={classes.menuItemStyle}>
            <Link to='/constructors'>{t('menu_1')}</Link>
        </Menu.Item>,
        <Menu.Item key="past-constructor" icon={<ConstructorIcon />} className={classes.menuItemStyle}>
            <Link to='/past_constructors'>{t('menu_7')}</Link>
        </Menu.Item>,
        <Menu.Item key="vrs" icon={<VRSServerIcon/>} className={classes.menuItemStyle}>
            <Link to='/vrs_servers'>{t('menu_6')}</Link>
        </Menu.Item>,
        <Menu.Item key="machine" icon={<MachineIcon/>} className={classes.menuItemStyle}>
            <Link to='/machines'>{t('menu_2')}</Link>
        </Menu.Item>,
        <Menu.Item key="user" icon={<StaffIcon/>} className={classes.menuItemStyle}>
            <Link to='/users'>{t('menu_3')}</Link>
        </Menu.Item>,
        <Menu.Item key="branch-settings"  icon={<BranchesOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
            <Link to='/branches'>{t('menu_4')}</Link>
        </Menu.Item>,
        <Menu.Item key="terminal-devices"  icon={<TerminalDeviceIcon/>} className={classes.menuItemStyle}>
            <Link to='/terminal_devices'>{t('menu_8')}</Link>
        </Menu.Item>,
        <Menu.Item key="general-settings" icon={<SettingOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
            <Link to='/settings'>{t('menu_5')}</Link>
        </Menu.Item>,
    ];
}

const systemUserMenu = (props, t) => {
    return [
        // <Menu.Item key="constructor" icon={<GatewayOutlined />} className={classes.menuItemStyle}>
        //     <Link to='/constructors'>{t('menu_1')}</Link>
        // </Menu.Item>,
        //  <Menu.Item key="past-constructor" icon={<GatewayOutlined />} className={classes.menuItemStyle}>
        //     <Link to='/past_constructors'>{t('menu_7')}</Link>
        // </Menu.Item>,
        // <Menu.Item key="vrs" icon={<MachineIcon/>} className={classes.menuItemStyle}>
        //     <Link to='/vrs_servers'>{t('menu_6')}</Link>
        // </Menu.Item>,
        // <Menu.Item key="machines" icon={<MachineIcon/>} className={classes.menuItemStyle}>
        //     <Link to='/machines'>{t('menu_2')}</Link>
        // </Menu.Item>,
        // <Menu.Item key="user" icon={<StaffIcon/>} className={classes.menuItemStyle}>
        //     <Link to='/users'>{t('menu_3')}</Link>
        // </Menu.Item>,
        // <Menu.Item key="branch-settings"  icon={<BranchesOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
        //     <Link to='/branches'>{t('menu_4')}</Link>
        // </Menu.Item>,
        <Menu.Item key="user-permissions" icon={<AuditOutlined/>} className={classes.menuItemStyle}>
            <Link to='/user_permissions'>User Permissions</Link>
        </Menu.Item>,
        // <Menu.Item key="general-settings" icon={<SettingOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
        //     <Link to='#'>{t('menu_5')}</Link>
        // </Menu.Item>,
        <Menu.Item key="auth-apps" icon={<AppstoreOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
            <Link to='/auth_apps'>Auth Apps</Link>
        </Menu.Item>,
        <Menu.Item key="api-servers" icon={<CloudServerOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
            <Link to='/api_servers'>API Servers</Link>
        </Menu.Item>,
        <Menu.Item key="api-events" icon={<ApiOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
            <Link to='/api_events'>API Events</Link>
        </Menu.Item>,
        <Menu.Item key="api-tasks" icon={<PicLeftOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
            <Link to='/api_tasks'>API Tasks</Link>
        </Menu.Item>,
        //  <Menu.Item key="terminal-devices"  icon={<BranchesOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
        //     <Link to='/terminal_devices'>{t('menu_8')}</Link>
        // </Menu.Item>,
        <Menu.Item key="terminal-applications"  icon={<BranchesOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
            <Link to='/terminal_applications'>Terminal Applications</Link>
        </Menu.Item>,
        <Menu.Item key="bucket-tool-configs"  icon={<TerminalDeviceIcon style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
            <Link to='/bucket_tool_configs'>Bucket Tool Configs</Link>
        </Menu.Item>,
        <Menu.Item key="system-infos" icon={<GithubFilled style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
            <Link to='/system_infos'>System Infos</Link>
        </Menu.Item>,
        <Menu.Item key="backup-restore" icon={<GithubFilled style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
            <Link to='/auth/backups'>Backup and Restore</Link>
        </Menu.Item>,
    ];
}

const getUserMenu = (props, t) => {
    return [
        <PermissionChecker key="profile" requiredPermissions={['user-detail']}>
            <Menu.Item key="profile" icon={<ProfileIcon/>} className={classes.menuItemStyle}>
                <Link to='/user/profile'>{t('menu_0')}</Link>
            </Menu.Item>
        </PermissionChecker>,
        <PermissionChecker key="constructor" requiredPermissions={['available-constructor-list']}>
            <Menu.Item key="constructor" icon={<ConstructorIcon />} className={classes.menuItemStyle}>
                <Link to='/constructors'>{t('menu_1')}</Link>
            </Menu.Item>
        </PermissionChecker>,
        <PermissionChecker key="vrs" requiredPermissions={['machine-vrs-file-list']}>
            <Menu.Item key="vrs" icon={<VRSServerIcon/>} className={classes.menuItemStyle}>
                <Link to='/vrs_servers'>{t('menu_6')}</Link>
            </Menu.Item>
        </PermissionChecker>,
        <PermissionChecker key="machine" requiredPermissions={['machine-list']}>
            <Menu.Item key="machine" icon={<MachineIcon/>} className={classes.menuItemStyle}>
                <Link to='/machines'>{t('menu_2')}</Link>
            </Menu.Item>
        </PermissionChecker>,
        <PermissionChecker key="user" requiredPermissions={['user-list']}>
            <Menu.Item key="user" icon={<StaffIcon/>} className={classes.menuItemStyle}>
                <Link to='/users'>{t('menu_3')}</Link>
            </Menu.Item>
        </PermissionChecker>

    ];
}

function buildMenu(props, auth, t) {
    if(auth.is_superuser){
        return systemUserMenu(props, t)
    }
    else if( auth.is_admin){
        return getAdminMenu(props, t);
    }
    else {
        return getUserMenu(props, t);
    }
}

const deriveMenuKey = (pathname) => {
    // Remove trailing slash if present
    const cleanedPath = pathname.replace(/\/$/, '');
    return pathNameKeyMapper[cleanedPath] || 'profile';
};

export const SiderMenu = ({props}) => {
    const {sharedData} = useData();
    const is_superuser = parseInt(sharedData.is_superuser||"0") || parseInt(window.sessionStorage.getItem('isSuperuser')||"0") || null;
    const is_adminuser = parseInt(sharedData.is_admin||"0") || parseInt(window.sessionStorage.getItem('isAdminUser')||"0") || null;
    const auth = {is_superuser: is_superuser, is_admin:is_adminuser}
    const { t } = useTranslation();
    let location = useLocation();
    const currentPath = deriveMenuKey(location.pathname);
    const [current, setCurrent] = useState(currentPath);

    useEffect(() => {
        if (current !== currentPath) {
            setCurrent(currentPath);
        }
    }, [location.pathname, currentPath, current]);

    const menus = buildMenu(props, auth, t);

    const handleMenuClick=(e)=>{
        setCurrent(e.key)
    }

    return (
        <Menu mode="inline" onClick={handleMenuClick} selectedKeys={[current]} className={classes.menu}>
            {menus}
        </Menu>
    );
};
