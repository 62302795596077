import React, {useState} from "react";
import DataTable from "../../components/DataTable/DataTable";
import {Button, Space, Typography} from "antd";
import {DownloadOutlined, PlusOutlined} from "@ant-design/icons";
import http from "../../http";
import {message} from "antd";


const BackupList = (props)=>{
    const handleDownload = (record) => {
        http.post(`/api/v1/backups/${record.id}/download/`, {}, { responseType: 'blob' })
            .then(response => {
                const filename = 'backup.zip'; // Set a default filename if not found
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);  // Set the desired file name.
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                // Handle errors, such as not finding the backup or network issues.
                console.error('Download error:', error);
            });
    }
    const columns = [
        {
            title: 'File Name',
            dataIndex: 'filename',
            key: 'filename',
        },
        {
            title: 'Created By',
            dataIndex: 'created_by_text',
            key: 'created_by_text',
        },
        {
            title:'Action',
            key: 'action',
            fixed:'right',
            render: (record) => (
                <Space size="middle">
                    <Typography.Link><DownloadOutlined style={{color:'#079327'}} onClick={()=>handleDownload(record)}/></Typography.Link>
                </Space>
            )
        }
    ]
    const handleBackupClick = ()=>{
        http.post('/api/v1/backups/').then((resp)=>{
            if(resp.status === 202){
                message.success(resp.data.message || 'DB Backup Started, Wait and reload after sometime')
            }
        }).catch((err)=>{
            message.error('Backup Failed! Contact Support')
        })
    }
    return (
        <DataTable
            pageTitle={'Database Backups'}
            columns={columns}
            url="/api/v1/backups"
            pagination={true}
            pageButtons={[
                <Button
                    type="primary"
                    shape={'circle'}
                    icon={<PlusOutlined/>}
                    style={{marginLeft: 16, background:'#377EF3'}}
                    onClick={()=>handleBackupClick()}
                />
            ]}
        />
    )
}

export default BackupList;