import {Layout, Button, Row, Col, Popover, Badge} from 'antd';
import React from 'react';
import classes from './BaseLayout.module.css'
import {useEffect, useState} from 'react';
import {SiderMenu} from "./Menu";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logout} from "../../actions/actions";
import {updatePermissions} from "../../actions/actions";
import LogoutIcon from "../CustomIcons/LogoutIcon";
import { useTranslation } from 'react-i18next';
import {changeLanguage} from "../../utilities/i18next_utils";
import http from "../../http";
import {useData} from "../../DataContext";
import store from "../../store";
import NotificationContent from "../NotificationContent/NotificationContent";
import {BellOutlined} from "@ant-design/icons";
import { useNotificationContext } from '../../NotificationProvider';


const { Header, Content, Sider } = Layout;

const BaseLayout = ({ children }) => {
    const { notifications } = useNotificationContext();
    const {sharedData, setData} = useData()
    const dispatch = useDispatch();
    const [userData, setUserData] = useState({});
    const storeState = store.getState();

    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const handleLanguageChange = (lang) => {
        changeLanguage(lang)
        // Add your logic to change the language here
    };
    const handleLogoOnClick = ()=>{
        navigate('/');
    }
    const handleLogout = ()=>{
        http.post('/o/logout/').then((resp)=>{
            setData({})
            dispatch(logout());
            navigate('/login');
        }).catch((err)=>{
            setData({})
            dispatch(logout());
            navigate('/login');
        })
    }
    useEffect(()=>{
        if(!Object.keys(sharedData).length && storeState?.auth?.isLoggedIn) {
            http.get('/o/get_user_info/').then((res) => {
                if (res.status === 200) {
                    setData(res.data);
                    setUserData(res.data);
                    window.sessionStorage.setItem('isSuperuser', res.data.is_superuser);
                    window.sessionStorage.setItem('isAdminUser', res.data.is_admin);
                    dispatch(updatePermissions(res.data.permitted_urls));
                }
            }).catch((err)=>{
                handleLogout();
                navigate('/login');
            })
        }
    }, [])
    return (
        <Layout style={{ height: '100vh' }}>
             <Header className={classes.header}>
                <Row className={classes.headerRow}>
                    <Col xs={4} className="logo" onClick={handleLogoOnClick} />
                    <Col xs={20} className={classes.menuWrapper}>
                        <div className={classes.menuContent}>
                            <div style={{margin: "10px"}}>
                                <Popover content={<NotificationContent notifications={notifications}/>} title={t('notification_title')} trigger="click">
                                    <Badge dot={notifications.length > 0}>
                                        <BellOutlined style={{ cursor: 'pointer', color: '#377EF3' }} />
                                    </Badge>
                                </Popover>
                            </div>
                            <div className={classes.languageButtonsContainer}>
                                <Button
                                    className={`${classes.languageButton1} ${i18n.language === 'ja' ? classes.activeLanguage : classes.inactiveLanguage}`}
                                    onClick={() => handleLanguageChange('ja')}
                                >
                                    日本語
                                </Button>
                                <Button
                                    className={`${classes.languageButton2} ${i18n.language === 'en' ? classes.activeLanguage : classes.inactiveLanguage}`}
                                    onClick={() => handleLanguageChange('en')}
                                >
                                    English
                                </Button>
                            </div>
                            <p className={classes.username}>
                                {sharedData?.username || userData?.username}
                            </p>
                            <div className={classes.logout} onClick={handleLogout}>
                                <LogoutIcon style={{ color: '#387FF7' }} />
                                <label>{t('logout')}</label>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Header>
            <Layout>
                <Sider
                    collapsible={true}
                    breakpoint={'lg'}
                    width={230}
                    style={{
                        background: '#FFFFFF',
                    }}
                >
                    <div style={{padding: '15px'}}>
                        <SiderMenu/>
                    </div>
                    <style>
                        {`
                            .ant-layout-sider-trigger {
                                background-color: #377EF3 !important;
                            }
                        `}
                    </style>
                </Sider>
                <Content
                    style={{
                        padding: 24,
                        margin: 0,
                        background: '#F7F7F7',
                        overflowY: 'hidden',
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
};
export default BaseLayout;