import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import {useTranslation} from "react-i18next";


const PastConstructorListPage = (props) => {
    const [reload, setReload] = useState(false);
    const { t } = useTranslation();

    const buildPageHeader = (data) =>{
        return null;
    }
    const buildCardHeader = (data)=>{
        return null;
    }

    const buildCardBody = (data)=>{
        return null;
    }

    const buildCardFooter=(data)=>{
        return null;
    }

    const renderFooterFields=(data)=>{
        return [<label style={{color: '#DC000C'}}>{t(data.status_text)}</label>]
    }

    const buildFooterFields=(data)=>{
        return renderFooterFields(data)
    }

    const config = {
        hideAddButton: true,
        disableDelete: true,
        hideEdit: true,
        disableDetail: true,
        url: '/api/v1/constructors/available_constructors/deleted_constructors/',
        pageTitle: t('past_constructor_list_title'),
        pageSubtitle: t('past_constructor_list_subtitle'),
        deleteTitle: t('constructor_delete_confirmation_text'),
        searchPlaceHolder: t('listpage_search_text') +'...',
        cardTitleField: 'full_name',
        cardDetailButtonText: t('listpage_detail_button_text'),
        cardDeleteButtonText: 'Delete Selected',
        cardBodyFields: {'email': t('constructor_addpage_email'), 'contact_no': t('constructor_addpage_contactno'), 'contact_person_name': t('constructor_contact_person_field')},
        cardFooterFields: [],
        addPermission: ['available-constructor-create'],
        editPermission: ['available-constructor-edit'],
        deletePermission: ['available-constructor-cancel-mediation', 'available-constructor-delete'],
        viewPermission: ['available-constructor-detail'],
        'buildPageHeader': buildPageHeader,
        'buildCardHeader': buildCardHeader,
        'buildCardBody': buildCardBody,
        'buildCardFooter': buildCardFooter,
        'buildFooterFields': buildFooterFields,
    }

    return (
        <>
            <ListPage
                config={config}
                reload={reload}
            />
        </>
    );
};

export default PastConstructorListPage;
