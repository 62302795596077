import {Divider, Row, Col, Space, Table, Typography, message} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import http from "../../http";
import {CloseOutlined, CheckOutlined, EditOutlined} from '@ant-design/icons';
import ExtensionRequestEditPage from './ExtensionRequestEditPage'
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";
import DataTable from "../../components/DataTable/DataTable";



const ConstructorMachineViewPage=(props)=>{
    const navigate = useNavigate();
    const location = useLocation();
    const {state} = location;
    const [loading, setLoading] = useState(false);
    const [constructorMachine, setConstructorMachine] = useState(props.constructorMachine);
    const [editItem, setEditItem] = useState(null);
    const [edit, setEditExtensionRequest] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { t } = useTranslation();

    const extensionColumns = [
        {
            title: t('extension_request_list_col1'),
            dataIndex: 'application_date',
            key: 'application_date',
        },
        {
            title: t('extension_request_list_col2'),
            dataIndex: 'from_date',
            key: 'startDate',
        },
        {
            title: t('extension_request_list_col3'),
            dataIndex: 'to_date',
            key: 'endDate',
        },
        {
            title: t('extension_request_list_col4'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('extension_request_list_col5'),
            dataIndex: 'status_text',
            render: (text)=>(
                <div>{t(text)}</div>
            ),
            key: 'status_text',
        }
    ]
    const returnColumns = [
        {
            title: t('lease_cancelrequest_col1'),
            dataIndex: 'return_date',
            key: 'startDate',
        },
        {
            title: t('lease_cancelrequest_col2'),
            dataIndex: 'status_text',
            key: 'statusText',
            render: (text)=>(
                <div>{t(text)}</div>
            ),
        }
    ]
    const fetchDefaultData =(constructor_machine)=>{
        http.get(`/api/v1/machines/constructor_machines/${constructor_machine?.id}/`).then((resp)=>{
            setLoading(false);
            setConstructorMachine(resp.data);
        }).catch((err)=>{
            setLoading(false);
        })
        setLoading(true);

    }

    useEffect(()=>{
        if(!props.constructorMachine){
            navigate('/content_not_found',{replace: false})
        }
        fetchDefaultData(props.constructorMachine)
    }, [props.constructorMachine])

    return(
        <>
            <div style={{overflowY:'auto', overflowX:'hidden', width: '100%', height:'100%'}}>
                <Row style={{display: 'flex', flexDirection:'column'}}>
                    <Col>
                        <Row style={{display: 'flex', flexDirection:'row'}}>
                            <Col>
                                <Col><label style={{color: '#5786EC'}}>{t('constructor_company_name_field')}</label>: {constructorMachine.constructor_data.company_name}</Col>
                                <Col><label style={{color: '#5786EC'}}>{t('constructor_contact_person_field')}</label>: {constructorMachine.constructor_data.contact_person_name}</Col>
                                <Col><label style={{color: '#5786EC'}}>{t('lease_history_col4')}</label>: {constructorMachine.machine_name}</Col>
                                <Col><label style={{color: '#5786EC'}}>{t('lease_history_col5')}</label>: {t(constructorMachine.machine_data?.machine_type_text)}</Col>
                                <Col><label style={{color: '#5786EC'}}>{t('lease_history_col6')}</label>: {constructorMachine.machine_data.identifier}</Col>
                            </Col>
                            <Divider type='vertical'/>
                            <Col>
                                <Col><label style={{color: '#5786EC'}}>{t('lease_history_col10')}</label> : {constructorMachine.reference_code}</Col>
                                <Col><label style={{color: '#5786EC'}}>{t('lease_start_date_original_label')}</label> : {constructorMachine.start_date}</Col>
                                <Col><label style={{color: '#5786EC'}}>{t('lease_end_date_original_label')}</label> : {constructorMachine.end_date}</Col>
                                <Col><label style={{color: '#5786EC'}}>{t('lease_start_date_label')}</label> : {constructorMachine.active_history?.start_date}</Col>
                                <Col><label style={{color: '#5786EC'}}>{t('lease_end_date_label')}</label>: {constructorMachine.active_history?.end_date}</Col>
                                {constructorMachine.machine_data?.machine_type == 1 ? <Col><label style={{color: '#5786EC'}}>{t('leaseadd_default_attachment_field')}</label>: {constructorMachine.default_bucket_text}</Col>:<div></div>}
                                {constructorMachine.machine_data?.machine_type == 1 ? <Col><label style={{color: '#5786EC'}}>{t('leaseadd_spare_attachment_field')}</label>: {constructorMachine.spare_bucket_text}</Col>:<div></div>}

                            </ Col>
                        </Row>
                    </Col>
                </Row>
                <Divider />
                <Row style={{display: 'flex', flexDirection:'column'}}>
                    <Col>
                        <p style={{fontWeight: "bold", fontSize: "800"}}> {t('lease_durationchangelist_title')} </p>
                    </Col>
                    <Col>
                        <DataTable
                            locale={{ emptyText: t('table_no_data_text') }}
                            loading={loading}
                            data={constructorMachine.extension_requests}
                            columns={extensionColumns}
                            pagination={false}
                        />
                    </Col>
                </Row>
                <Divider style={{marginTop: '10px'}}/>
                <Row style={{display: 'flex', flexDirection:'column'}}>
                    <Col>
                        <p style={{fontWeight: "bold", fontSize: "800"}}> {t('lease_cancelrequestlist_title')} </p>
                    </Col>
                    <Col>
                        <DataTable
                            locale={{ emptyText: t('table_no_data_text') }}
                            loading={loading}
                            data={constructorMachine.return_requests}
                            columns={returnColumns}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </div>
        </>

    )
}

export default ConstructorMachineViewPage;
