import PermissionDenied from "./PermissionDenied";
import LoginPage from "./pages/LoginPage/LoginPage";
import UserListPage from "./pages/Users/UserListPage";
import UserPermission from "./pages/Users/UserPermission";
import BranchListPage from "./pages/Branches/BranchListPage";
import ConstructorListPage from "./pages/Constructors/ConstructorListPage";
import MachineListPage from "./pages/Machines/MachineListPage";
import ConstructorDetailPage from "./pages/Constructors/ConstructorDetailPage";
import MachineDetailPage from "./pages/Machines/MachineDetailPage";
import UserDetailPage from "./pages/Users/UserDetailPage";
import AuthAppListPage from "./pages/AuthApps/AuthAppListPage";
import APIServerListPage from "./pages/APIServers/APIServerListPage";
import APIEventListPage from "./pages/APIEvents/APIEventListPage";
import APITaskListPage from "./pages/APITasks/APITaskListPage";
import SystemInfoListPage from "./pages/SystemInfos/SystemInfoListPage";
import ContentNotFound from "./ContentNotFound";
import VRSServerListPage from "./pages/VRS Servers/VRSServerListPage";
import VRSServerDetailPage from "./pages/VRS Servers/VRSServerDetailPage";
import UserProfilePage from "./pages/Users/UserProfile";
import BackupList from "./pages/Backup/BackupList";
import GeneralSettings from "./pages/Settings/Settings";
import PastConstructorListPage from "./pages/Constructors/PastConstructorListPage";
import TerminalDeviceListPage from "./pages/TerminalDevices/TerminalDeviceListPage";
import TerminalAppListPage from "./pages/TerminalApps/TerminalAppListPage";
import BucketToolConfigListPage from "./pages/BucketToolConfig/BucketToolConfigListPage";


const routes = [
    {
        name: 'Login',
        path: '/login',
        exact: true,
        component: LoginPage,
        requiredPermission: null
    },
    {
        name: 'Home',
        path: '/',
        exact: true,
        component: UserProfilePage,
        requiredPermission: ['user-detail']
    },
    {
        name: 'Users',
        path: '/users',
        exact: true,
        component: UserListPage,
        requiredPermission: ['user-list']
    },
    {
        name: 'User Detail',
        path: '/user/detail',
        exact: true,
        component: UserDetailPage,
        requiredPermission: ['user-detail']
    },
    {
        name: 'User Permission',
        path: '/user_permissions',
        exact: true,
        component: UserPermission,
        requiredPermission: ['system_urls']
    },
    {
        name: 'Branch List',
        path: '/branches',
        exact: true,
        component: BranchListPage,
        requiredPermission: ['branch-list']
    },
    {
        name: 'Constructors',
        path: '/constructors',
        exact: true,
        component: ConstructorListPage,
        requiredPermission: ['available-constructor-list']
    },
    {
        name: 'Constructor Detail',
        path: '/constructor/detail',
        exact: true,
        component: ConstructorDetailPage,
        requiredPermission: ['available-constructor-detail']
    },
    {
        name: 'Machine',
        path: '/machines',
        exact: true,
        component: MachineListPage,
        requiredPermission: ['machine-list']
    },
    {
        name: 'Machine Detail',
        path: '/machine/detail',
        exact: true,
        component: MachineDetailPage,
        requiredPermission: ['machine-detail']
    },
    {
        name: 'Auth Apps',
        path: '/auth_apps/',
        exact: true,
        component: AuthAppListPage,
        requiredPermission: ['auth-apps-list']
    },
    {
        name: 'API Servers',
        path: '/api_servers/',
        exact: true,
        component: APIServerListPage,
        requiredPermission: ['api-servers-list']
    },
    {
        name: 'API Events',
        path: '/api_events/',
        exact: true,
        component: APIEventListPage,
        requiredPermission: ['api-events-list']
    },
    {
        name: 'API Tasks',
        path: '/api_tasks/',
        exact: true,
        component: APITaskListPage,
        requiredPermission: ['api-tasks-list']
    },
    {
        name: 'System Infos',
        path: '/system_infos/',
        exact: true,
        component: SystemInfoListPage,
        requiredPermission: ['system_info']
    },
    {
        name: 'PermissionDenied',
        path: '/permission_denied',
        exact: true,
        component: PermissionDenied
    },
    {
        name: 'Content Not Found',
        path: '/content_not_found',
        exact: true,
        component: ContentNotFound
    },
    {
        name: 'VRS Servers',
        path: '/vrs_servers',
        exact: true,
        component: VRSServerListPage,
        requiredPermission: ['machine-vrs-file-list']
    },
    {
        name: "VRS Server Details",
        path: '/vrs_server/detail/',
        exact: true,
        component: VRSServerDetailPage,
        requiredPermission: ['machine-vrs-file-detail']
    },
    {
        name: "User Profile",
        path: '/user/profile',
        exact: true,
        component: UserProfilePage,
        requiredPermission: ['user-detail']
    },
    {
        name: "Backup and Restore",
        path: '/auth/backups',
        exact: true,
        component: BackupList
    },
    {
        name: "Settings",
        path: '/settings',
        exact: true,
        component: GeneralSettings
    },
    {
        name: "Past Constructors",
        path: '/past_constructors',
        exact: true,
        component: PastConstructorListPage,
        requiredPermission: ['past-constructor-list']
    },
    {
        name: "Terminal Devices",
        path: '/terminal_devices',
        exact: true,
        component: TerminalDeviceListPage,
        requiredPermission: ['terminal-device-list']
    },
    {
        name: 'Terminal Applications',
        path: '/terminal_applications/',
        exact: true,
        component: TerminalAppListPage,
        requiredPermission: null
    },
     {
        name: 'Bucket Tool Configs',
        path: '/bucket_tool_configs/',
        exact: true,
        component: BucketToolConfigListPage,
        requiredPermission: null
    },
]

export default routes;
