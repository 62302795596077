import DataTable from "../../components/DataTable/DataTable";
import {useState} from "react";
import {Button} from "antd";
import BucketToolConfigAddPage from "./BucketToolConfigAddPage";
import {PlusOutlined} from "@ant-design/icons";


const BucketToolConfigListPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [reload, setReload] = useState(false);
    const [edit, setEdit] = useState(false);
    const [item, setItem] = useState(false);
    const handleOpenModal = (item, edit) => {
        if(edit){
            setEdit(true);
            setItem(item);
            setShowModal(true);
        }
        else {
            setEdit(false);
            setItem(null);
            setShowModal(true);

        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEdit(false);
        setItem(null);
    };
    const handleSuccessAdd = () =>{
        setShowModal(false);
        setEdit(false);
        setItem(null);
        setReload(!reload);
    }

    const columns = [
        {
            title: 'Tool ID',
            dataIndex: 'tool_id',
            key: 'tool_id',
            width: '10%'
        },
        {
            title: 'Offset',
            dataIndex: 'offset',
            key: 'offset',
            width: '10%',
        },
        {
            title: 'Calc. Ax',
            dataIndex: 'cal_ax',
            key: 'cal_ax',
            render: (text) => (
                     <div style={{ wordWrap: 'break-word', wordBreak: 'break-all', textOverflow:"-" }}>{text? JSON.stringify(text): ''}</div>
             ),
            width: '10%',
        }
    ];

    const searchFields = [
        {
            name: 'tool_id',
            type: 'input',
            label: 'Tool ID',
            placeholder: 'Tool ID'
        }
    ];

    return (
        <>
            <DataTable
                reload={reload}
                pageTitle={'Bucket Tool Configs'}
                columns={columns}
                url="/api/v1/bucket_tool_configs/"
                searchFields={searchFields}
                pagination={true}
                pageButtons={[
                    <Button
                        type="primary"
                        shape={'circle'}
                        icon={<PlusOutlined/>}
                        style={{marginLeft: 16, background:'#377EF3'}}
                        onClick={()=>handleOpenModal()}
                    />
                ]}
            />
            <BucketToolConfigAddPage
                visible={showModal}
                onClose={handleCloseModal}
                onSuccessSubmit={handleSuccessAdd}
                edit={edit}
                editItem={item}
                reload={reload}
            />
        </>
    );
};

export default BucketToolConfigListPage;