import {Divider, Row, Col, Space, Button, Modal, message, Tooltip} from 'antd';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {
    DeleteOutlined,
    RiseOutlined,
    UploadOutlined,
    CheckOutlined, CloseOutlined,
} from '@ant-design/icons';
import http from '../../http';
import ConstructorMachineAddPage from './ConstructorMachineAddPage';
import MachineRegistryFileAddPage from "./MachineRegistryFileAddPage";
import ConstructorMachineView from "../ConstructorMachines/ConstructorMachineView"
import {getReadableDateFromTimeStamp} from '../../utils/common'
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";
import DataTable from "../../components/DataTable/DataTable";
import CustomTimeline from "../../components/Timeline/Timeline";
import MachinePosition from "./MachinePosition";

const MachineDetailPage=(props)=>{
    const location = useLocation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [viewModalVisible, setViewModalVisible] = useState(false);
    // const [qrModalVisible, setQRModalVisible] = useState(false);
    // const [qrCodeUrl, setQRCodeUrl] = useState('');
    const [constructorMachine, setConstructorMachine] = useState({});
    const [isMachineRegistryModalVisible, setIsMachineRegistryModalVisible] = useState(false);
    const [isRegistrySwitchModalVisible, setSwitchModalVisible] = useState(false);
    const [isMachineRegistryReplaceModalVisible, setIsMachineRegistryReplaceModalVisible] = useState(false);
    const {state} = location;
    const [loading, setLoading] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [edit, setEditConstructorMachine] = useState(false)
    const [assignmentTableReload, setAssignmentTableReload] = useState(true);
    const [runningassignmentTableReload, setRunningassignmentTableReload] = useState(true);
    const [bucketDataReload, setBucketDataReload] = useState(true);
    const [registryFileReload, setRegistryFileReload] = useState(true);
    const [machine, setMachine] = useState(state?.machine);
    const [isValidState, setValidState] = useState(!!state?.machine);
    const [machineSchedules, setMachineSchedule] = useState([])
    const { t } = useTranslation();

    const navigate = useNavigate();

    const fetchMachineData=()=>{
        http.get(`/api/v1/machines/${machine?.id}/`).then((resp)=>{
            setLoading(false);
            setMachine(resp.data);
        }).catch((err)=>{
            setLoading(false);
        })
    }

    const fetchMachineSchedule=()=>{
        http.get(`/api/v1/machines/${machine?.id}/rental_schedules/`, ).then((resp)=>{
            if(resp.status === 200){
                setMachineSchedule(resp.data)
            }
        }).catch((err)=>{
            setLoading(false);
        })
    }

    // const fetchQRCode = ()=>{
    //     http.get(`/api/v1/machines/${machine?.id}/qr_code/`, ).then((resp)=>{
    //         const binaryDataString=atob(resp.data.qr);
    //         const byteNumbers = new Array(binaryDataString.length);
    //
    //         for (let i = 0; i < binaryDataString.length; i++) {
    //             byteNumbers[i] = binaryDataString.charCodeAt(i);
    //         }
    //
    //         const byteArray = new Uint8Array(byteNumbers);
    //         const blob = new Blob([byteArray], {type: 'image/png'});
    //         const url = URL.createObjectURL(blob);
    //         setQRCodeUrl(url);
    //     }).catch((err)=>{
    //         setLoading(false);
    //     })
    // }

    useEffect(()=>{
        if(!state){
            navigate('/content_not_found',{replace: true})
        }
        if(isValidState) {
            fetchMachineData()
            // fetchQRCode()
            fetchMachineSchedule()
        }
    }, [isValidState])

    const handleConstructorMachineEdit = (record)=>{
        setEditConstructorMachine(true);
        setEditItem(record);
        setIsModalVisible(true);
    }

    const renderEditIcon=(record)=>{
        if(record.status === 0){
            return <Button
                className={'bordered-default-button'}
                onClick={()=>handleConstructorMachineEdit(record)}
            >{t('rental_record_edit_button_text')}
            </Button>
        }
        return null;
    }

    const renderSubmitIcon=(record)=>{
        if(record.status === 0) {
            return <CustomConfirmPopup
                title={t('leaserecord_approve_confirmation_text')}
                onConfirm={() => handleSubmitConstructorMachine(record)}
                icon={<RiseOutlined/>}
                okText={t('yes_text')}
                cancelText={t('no_text')}
                disabled={false}
                okType='default'
            >
                <Button type={'primary'} className={'primary-button'}>{t('rental_record_submit_button_text')}</Button>
            </CustomConfirmPopup>
        }
        return null
    }

    const handleSubmitConstructorMachine = (record) => {
        if (!record.machine_data.status) {
            message.error(t('machine_not_registered_validation_text'))
        } else {
            http.post(`/api/v1/machines/constructor_machines/${record.id}/1/record_machine_lease/`).then((resp)=>{
                if (resp.status === 200) {
                    message.success(t('leaserecord_submit_successful_text'))
                    setLoading(false);
                    setAssignmentTableReload(!assignmentTableReload);
                    setRunningassignmentTableReload(!runningassignmentTableReload)
                    fetchMachineData();
                    fetchMachineSchedule();

                }
            }).catch((err)=>{
                setLoading(false);
                message.error(t(err.response.data.error))
            })
        }
    }

    const handleRegistryFileDownload=(record)=>{
        http.get(`/api/v1/machines/machine_registry_files/${record.id}/download/`).then((resp)=>{
            if(resp.status === 200){
                // Convert the JSON response data to a Blob
                const blob = new Blob([JSON.stringify(resp.data, null, 2)], { type: 'application/json' });

                // Create a link element
                const link = document.createElement('a');

                // Set the download attribute with a filename
                link.download = `${record.name}`;

                // Create a URL for the Blob and set it as the href attribute
                link.href = window.URL.createObjectURL(blob);

                // Append the link to the body
                document.body.appendChild(link);

                // Trigger the download by simulating a click
                link.click();

                // Clean up by removing the link element
                document.body.removeChild(link);
            }

        }).catch((err)=>{})
    }

    const fileColumns = [
        {
            title: t('profile_filelist_col1'),
            dataIndex: 'created',
            key: 'created',
            render: (value) => {
                let readable_timestamp = getReadableDateFromTimeStamp(value);
                return <div>{readable_timestamp}</div>
            }
        },
        {
            title: t('profile_filelist_col2'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('profile_filelist_col3'),
            dataIndex: 'status_text',
            key: 'status',
            render: (text) => (
                <div style={{ color: text == 'active_label' ? '#079327': '#DC000C'}}>{t(text)}</div>
            ),
        },
        {
            title: t('bucket_col7'),
            key: 'action',
            fixed:'right',
            width: '15%',
            render: (record) => (
                <Space size="middle">
                    <PermissionChecker requiredPermissions={['machine-registry-file-download']}>
                        <Button type={'primary'} onClick={()=>handleRegistryFileDownload(record)}>{t('download_button_text')}</Button>
                    </PermissionChecker>
                </Space>
            )
        }
    ]

    const handleAttachmentFileDownload=(record)=>{
        http.get(`/api/v1/machines/buckets/${record.id}/download/`).then((resp)=>{
            if(resp.status === 200){
                // Convert the JSON response data to a Blob
                const blob = new Blob([JSON.stringify(resp.data, null, 2)], { type: 'application/json' });

                // Create a link element
                const link = document.createElement('a');

                // Set the download attribute with a filename
                link.download = `${record.name}.json`;

                // Create a URL for the Blob and set it as the href attribute
                link.href = window.URL.createObjectURL(blob);

                // Append the link to the body
                document.body.appendChild(link);

                // Trigger the download by simulating a click
                link.click();

                // Clean up by removing the link element
                document.body.removeChild(link);
            }

        }).catch((err)=>{})
    }

    const bucket_columns = [
        {
            title: t('profile_filelist_col1'),
            dataIndex: 'created',
            key: 'created',
            render: (value) => {
                let readable_timestamp = getReadableDateFromTimeStamp(value);
                return <div>{readable_timestamp}</div>
            }
        },
        {
            title: t('bucket_col1'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('bucket_col7'),
            key: 'action',
            fixed:'right',
            width: '15%',
            render: (record) => (
                <Space size="middle">
                    <PermissionChecker requiredPermissions={['machine-bucket-profile-download']}>
                        <Button type={'primary'} onClick={()=>handleAttachmentFileDownload(record)}>{t('download_button_text')}</Button>
                    </PermissionChecker>
                </Space>
            )
        }
    ]
    const handleExtensionRequest=(record, status)=>{
        let url = `/api/v1/machines/lease_extension_requests/${record.active_extension_request_id}/${status === 1? 'approve':'reject'}/`;
        http.post(url).then((resp)=>{
            if(resp.status === 200){
                message.success(t(resp.data.message) || 'Operation Successful');
                setAssignmentTableReload(!assignmentTableReload);
                setRunningassignmentTableReload(!runningassignmentTableReload)
                fetchMachineSchedule()
            }
            else{
                message.error(t(resp.data.error) || 'Operation Failed');
            }
        }).catch((err)=>{
            message.error(t(err.response.data.error))
        })
    }
    const handleReturnRequest=(record, status)=>{
        let url = `/api/v1/machines/return_requests/${record.active_return_request_id}/${status === 1? 'acknowledge':'reject'}/`;
        http.post(url).then((resp)=>{
            if(resp.status === 200){
                message.success(t(resp.data.message) || 'Operation Successful');
                setAssignmentTableReload(!assignmentTableReload);
                setRunningassignmentTableReload(!runningassignmentTableReload)
                fetchMachineSchedule();
                fetchMachineData();

            }
            else{
                message.error(t(resp.data.error) || 'Operation Failed');
            }
        }).catch((err)=>{
            message.error(t(err.response.data.error) || 'Operation Failed')
        })
    }

    const runningColumns = [
        {
            width: '10%',
            title: t('lease_historylist_col1'),
            dataIndex: 'contract_code',
            key: 'contract_code',
        },
        {
            width: '10%',
            title: t('lease_historylist_col2'),
            dataIndex: 'start_date',
            key: 'startDate',
            render: (text)=>(
                <div>{text}</div>
            )
        },
        {
            width: '10%',
            title: t('lease_historylist_col3'),
            dataIndex: 'end_dates',
            key: 'endDate',
            render: (text)=> {
                if(text.extended_end_date){
                    return (
                        <>
                            <div style={{color:'rgb(201, 143, 65)'}}>{text.extended_end_date}</div>
                            <div>{text.current_end_date}</div>
                        </>
                    )
                }
                else{
                    return <div>{text.current_end_date}</div>
                }
            }
        },

        // {
        //     width: '10%',
        //     title: t('lease_historylist_col4'),
        //     dataIndex: 'machine_name',
        //     key: 'machineName',
        // },
        // {
        //     width: '10%',
        //     title: t('lease_historylist_col5'),
        //     dataIndex: 'machine_data',
        //     key: 'machineId',
        //     render: (text)=>(
        //         <div>{text.number}</div>
        //     )
        // },
        {
            width: '10%',
            title: t('lease_historylist_col7'),
            dataIndex: 'constructor_data',
            key: 'leaseTo',
            render: (text) => (
                <Tooltip title={text.company_name} placement={'leftTop'}>
                    <div style={{
                        wordWrap: 'break-word', wordBreak: 'break-all', overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}>
                        {text.company_name}
                    </div>
                </Tooltip>
            ),
        },
        {
            width: '10%',
            title: t('leaseadd_default_attachment_field'),
            dataIndex: 'default_bucket_text',
            render: (text)=>(
                <Tooltip title={text} placement={'leftTop'}>
                    <div style={{
                        wordWrap: 'break-word', wordBreak: 'break-all', overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}>{text}</div>
                </Tooltip>
            ),
            key: 'statusText',
        },
        {
            width: '15%%',
            title: t('leaseadd_spare_attachment_field'),
            dataIndex: 'spare_bucket_text',
            render: (text)=>(
                <Tooltip title={text} placement={'leftTop'}>
                    <div style={{
                        wordWrap: 'break-word', wordBreak: 'break-all', overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}>{text}</div>
                </Tooltip>
            ),
            key: 'statusText',
        },
        {
            title: '',
            width: '23%',
            key: 'action',
            fixed:'right',
            render: (record) => (
                record.status === 1 ?
                    <Space size="middle">
                        {record.active_extension_request ?<CustomConfirmPopup
                            title={t('extension_request_approve_confirmation_text')}
                            onConfirm={()=>handleExtensionRequest(record, 1)}
                            icon={<CheckOutlined/>}
                            okText={t('yes_text')}
                            cancelText={t('no_text')}
                            disabled={false}
                            okType='default'
                        >
                            <PermissionChecker requiredPermissions={['machine-lease-extension-requests-approve']}>
                                <Button type={'primary'} className={'primary-button'}>{t('extension_request_approve_button_text')}</Button>
                            </PermissionChecker>
                        </CustomConfirmPopup>: null}
                        {record.active_extension_request ?<CustomConfirmPopup
                            title={t('extension_request_reject_confirmation_text')}
                            onConfirm={()=>handleExtensionRequest(record, 2)}
                            icon={<CloseOutlined/>}
                            okText={t('yes_text')}
                            cancelText={t('no_text')}
                            disabled={false}
                            okType='default'
                        >
                            <PermissionChecker requiredPermissions={['machine-lease-extension-requests-reject']}>
                                <Button className={'button-cancel'}>{t('extension_request_reject_button_text')}</Button>
                            </PermissionChecker>
                        </CustomConfirmPopup>:null}
                        {record.active_return_request ?<CustomConfirmPopup
                            title={t('return_request_acknowledge_confirmation_text')}
                            onConfirm={()=>handleReturnRequest(record, 1)}
                            icon={<CheckOutlined/>}
                            okText={t('yes_text')}
                            cancelText={t('no_text')}
                            disabled={false}
                            okType='default'
                        >
                            <PermissionChecker requiredPermissions={['machine-return-requests-acknowledge']}>
                                <Button type={'primary'} className={'primary-button'}>{t('return_request_acknowledge_button_text')}</Button>
                            </PermissionChecker>
                        </CustomConfirmPopup>: null}
                        {/*{record.active_return_request?<CustomConfirmPopup*/}
                        {/*    title={t('return_request_reject_confirmation_text')}*/}
                        {/*    onConfirm={()=>handleReturnRequest(record, 2)}*/}
                        {/*    icon={<CloseOutlined/>}*/}
                        {/*    okText={t('yes_text')}*/}
                        {/*    cancelText={t('no_text')}*/}
                        {/*    disabled={false}*/}
                        {/*    okType='default'*/}
                        {/*>*/}
                        {/*    <PermissionChecker requiredPermissions={['machine-return-requests-reject']}>*/}
                        {/*        <Button className={'button-cancel'}>Reject</Button>*/}
                        {/*    </PermissionChecker>*/}
                        {/*</CustomConfirmPopup>:null}*/}
                    </Space>: record.status === 0 ?
                        <Space size="middle">
                            <PermissionChecker requiredPermissions={['record-machine-lease', 'update-machine-lease']}>
                                {renderSubmitIcon(record)}
                            </PermissionChecker>
                            <PermissionChecker requiredPermissions={['constructor-machine-edit']}>
                                {renderEditIcon(record)}
                            </PermissionChecker>
                            {record.status === 0 ? <CustomConfirmPopup
                                title={t('leaserecord_delete_confirmation_text')}
                                onConfirm={()=>handleAssignmentDelete(record)}
                                icon={<DeleteOutlined/>}
                                okText={t('yes_text')}
                                cancelText={t('no_text')}
                                disabled={false}
                                okType='default'
                            >
                                <PermissionChecker requiredPermissions={['constructor-machine-delete']}>
                                    <Button className={'button-cancel'}>{t('rental_record_delete_button_text')}</Button>
                                </PermissionChecker>
                            </CustomConfirmPopup> : null}
                        </Space>:<></>
            )
        },
    ]

    const columns = [
        {
            title: t('lease_historylist_col1'),
            dataIndex: 'contract_code',
            key: 'contract_code',
        },
        {
            title: t('lease_historylist_col9'),
            dataIndex: 'reference_code',
            key: 'reference_code',
        },
        {
            title: t('lease_historylist_col2'),
            dataIndex: 'start_date',
            key: 'startDate',
            render: (text)=>(
                <div>{text}</div>
            )
        },
        {
            title: t('lease_historylist_col3'),
            dataIndex: 'active_history',
            key: 'endDate',
            render: (text)=>(
                <div>{text.end_date}</div>
            )
        },
        // {
        //     title: t('lease_historylist_col4'),
        //     dataIndex: 'machine_name',
        //     key: 'machineName',
        // },
        // {
        //     title: t('lease_historylist_col5'),
        //     dataIndex: 'machine_data',
        //     key: 'machineId',
        //     render: (text)=>(
        //         <div>{text.number}</div>
        //     )
        // },
        {
            title: t('lease_historylist_col6'),
            dataIndex: 'status_text',
            render: (text)=>(
                <div>{t(text)}</div>
            ),
            key: 'statusText',
        },
        {
            title: t('lease_historylist_col7'),
            dataIndex: 'constructor_data',
            key: 'leaseTo',
            render: (text) => (
                <div>{(text.company_name)}</div>
            ),
        },
        {
            title: '',
            key: 'action',
            fixed:'right',
            render: (record) => (
                <Space size="middle">
                    {/*<PermissionChecker requiredPermissions={['constructor-machine-edit']}>*/}
                    {/*    {renderEditIcon(record)}*/}
                    {/*</PermissionChecker>*/}
                    {/*<PermissionChecker requiredPermissions={['record-machine-lease', 'update-machine-lease']}>*/}
                    {/*    {renderSubmitIcon(record)}*/}
                    {/*</PermissionChecker>*/}
                    {/*{record.status === 0 ? <CustomConfirmPopup*/}
                    {/*    //                 title={t('leaserecord_delete_confirmation_text')}*/}
                    {/*    //                 onConfirm={()=>handleAssignmentDelete(record)}*/}
                    {/*    //                 icon={<DeleteOutlined/>}*/}
                    {/*    //                 okText={t('yes_text')}*/}
                    {/*    //                 cancelText={t('no_text')}*/}
                    {/*    //                 disabled={false}*/}
                    {/*    //                 okType='default'*/}
                    {/*    //             >*/}
                    {/*    //                 <PermissionChecker requiredPermissions={['constructor-machine-delete']}>*/}
                    {/*    //                     <Typography.Link><DeleteOutlined/></Typography.Link>*/}
                    {/*    //                 </PermissionChecker>*/}
                    {/*    //             </CustomConfirmPopup> : null}*/}
                    <PermissionChecker requiredPermissions={['constructor-machine-detail']}>
                        <Button onClick={()=>{setConstructorMachine(prevData=>{
                                return {...prevData, ...record}
                            }
                        );
                            setViewModalVisible(true);
                        }} className={'bordered-default-button'}>{t('rental_record_view_button_text')}</Button>
                    </PermissionChecker>
                </Space>
            )
        },
    ]

    const handleAssignmentDelete=(record)=>{
        http.delete(`/api/v1/machines/constructor_machines/${record.id}/delete/`).then((resp)=>{
            if(resp.status === 204){
                message.success(t('leaserecord_delete_successful_message'));
                setAssignmentTableReload(!assignmentTableReload);
                setRunningassignmentTableReload(!runningassignmentTableReload)
                fetchMachineSchedule()

            }
            else{
                message.error(t('leaserecord_delete_failed_message'));
            }
        })
    }

    const buildBreadcrumbs=()=>{
        let breadcrumbs = [];
        if(state?.breadcrumbs){
            state?.breadcrumbs.map((item, index)=>{
                breadcrumbs.push(
                    <><Link to={item.to} state={item.state} key={index}>{item.title}</Link> > </>
                )
            })
            breadcrumbs.push(<>{machine?.name}</>)
            return breadcrumbs;
        }
        return <><Link to={'/machines'}>{t('machinelist_title')}  </Link> >  {machine?.name}</>;
    }

    const handleSuccessAdd = () =>{
        setEditConstructorMachine(false);
        setEditItem(null);
        setIsModalVisible(false);
        setAssignmentTableReload(!assignmentTableReload);
        setRunningassignmentTableReload(!runningassignmentTableReload);
        fetchMachineSchedule();
    }
    const handleSuccessAddMachineRegistry = () =>{
        setIsMachineRegistryModalVisible(false);
        setIsMachineRegistryReplaceModalVisible(false);
        setSwitchModalVisible(false);
        setRegistryFileReload(!registryFileReload);
        setBucketDataReload(!bucketDataReload);
        setRunningassignmentTableReload(!runningassignmentTableReload)
        setAssignmentTableReload(!assignmentTableReload)
    }

    const renderConstructorMachineForm = () => {
        return (
            <ConstructorMachineAddPage
                visible={isModalVisible}
                onClose={()=>{setEditItem(null); setEditConstructorMachine(false); setIsModalVisible(false)}}
                machine={machine}
                edit={edit}
                editItem={editItem}
                onSuccessSubmit={handleSuccessAdd}
            />
        );
    };
    const renderMachineRegistryFileForm = () => {
        return (
            <MachineRegistryFileAddPage
                visible={isMachineRegistryModalVisible}
                onClose={()=>{setEditItem(null); setEditConstructorMachine(false); setIsMachineRegistryModalVisible(false)}}
                machine={machine}
                onSuccessSubmit={handleSuccessAddMachineRegistry}
            />
        );
    };

    const renderMachineRegistryReplaceFileForm = ()=>{
        return (
            <MachineRegistryFileAddPage
                visible={isMachineRegistryReplaceModalVisible}
                onClose={()=>{setEditItem(null); setEditConstructorMachine(false); setIsMachineRegistryReplaceModalVisible(false)}}
                machine={machine}
                replace={true}
                onSuccessSubmit={handleSuccessAddMachineRegistry}
            />
        );
    }

    const handleViewModalCancel=()=>{
        setAssignmentTableReload(!assignmentTableReload);
        setViewModalVisible(false)
    }
    // const handleQRModalCancel=()=>{
    //     setQRModalVisible(false)
    // }
    // const handleQRCodeClick=()=>{
    //     setQRModalVisible(true)
    // }
    // const handlePrint = () => {
    //     // Open a new window
    //     const printWindow = window.open('', '_blank');
    //
    //     // Write the content to the new window
    //     printWindow.document.write('<html><head><title>Print</title></head><body>');
    //     printWindow.document.write('<style>.print-only { display: block; }</style>');
    //     printWindow.document.write(document.querySelector('.print-only').innerHTML);
    //     printWindow.document.write('</body></html>');
    //
    //     // Close the document and trigger printing
    //     printWindow.print();
    //     printWindow.close();
    //
    // };
    const leaseHistorySearchFields = [
        {
            name: 'status',
            type: 'select',
            label: 'Rental Status',
            placeholder: 'Select Rental Status',
            extraProps: {
                withTranslation: true,
                url: '/api/v1/enums/ConstructorMachineStatusChoices/combo/'
            }
        }]

    const getRowClassName=(record, index)=>{
        if(record.active_return_request) {
            return 'highlight-datatable-row-red'
        }
        else if(record.active_extension_request){
            return 'highlight-datatable-row-yellow'
        }
        return null
    }

    const handleLicenseSubmission=()=>{
        if(machine?.license_application){
            http.post(`/api/v1/machine_license_applications/${machine?.license_application?.id}/submit/`).then((resp)=>{
                if(resp.status === 200){
                    message.success(t('machine_license_application_submit_successful_message'));
                    fetchMachineData();
                }
                else{
                    message.error(t(resp?.data?.error))
                }
            }).catch((error)=>{
                message.error(t(error.response?.data?.error))
            })

        }
    }

    if(isValidState) {
        return (
            <>
                {renderConstructorMachineForm()}
                {renderMachineRegistryFileForm()}
                {renderMachineRegistryReplaceFileForm()}
                <Modal
                    open={viewModalVisible}
                    okText={t('modal_okText')}
                    cancelText={t('modalCancelText')}
                    title={t('contract_detail_view_title', {contract_code: constructorMachine?.contract_code})}
                    onCancel={handleViewModalCancel}
                    onOk={handleViewModalCancel}
                    maskClosable={false}
                    width={800}
                >
                    <ConstructorMachineView constructorMachine={constructorMachine}/>
                </Modal>
                {/*<Modal*/}
                {/*    open={qrModalVisible}*/}
                {/*    title={`QR Code for ${machine?.name}`}*/}
                {/*    onCancel={handleQRModalCancel}*/}
                {/*    onOk={handleQRModalCancel}*/}
                {/*    maskClosable={false}*/}
                {/*    footer={[*/}
                {/*        <Button key="download">*/}
                {/*            <a*/}
                {/*                key="download"*/}
                {/*                href={qrCodeUrl}*/}
                {/*                download={`machine_qr_code_${machine?.id}.png`}*/}
                {/*                target="_blank"*/}
                {/*                rel="noopener noreferrer"*/}
                {/*            >*/}
                {/*                Download*/}
                {/*            </a>*/}
                {/*        </Button>,*/}
                {/*        <Button key="submit" type="primary" onClick={() => handlePrint()} style={{background: '#377EF3'}}>*/}
                {/*            Print*/}
                {/*        </Button>,*/}

                {/*    ]}*/}
                {/*>*/}
                {/*    <div className={'print-only'}>*/}
                {/*        <img src={qrCodeUrl} alt="QR Code" style={{width: '100%'}}/>*/}
                {/*    </div>*/}
                {/*</Modal>*/}
                <div style={{overflowY: 'auto', overflowX: 'hidden', width: '100%', height: '100%'}}>
                    <Row style={{height: '5%', color: 'black', fontWeight: 'bold', justifyContent: 'space-between'}}>
                        <Col>
                            <Space>{buildBreadcrumbs()}</Space>
                        </Col>
                        <Col>
                            <Space>
                                {machine.license_status === 2 ?
                                    <PermissionChecker requiredPermissions={['constructor-machine-create']}>
                                        <Button type={'primary'}
                                                onClick={() => setIsModalVisible(true)}>{t('machine_lease_button_text')}</Button>
                                    </PermissionChecker>: (machine.license_status === 0 || machine.license_status === 3 ||machine.license_status === 4) ?
                                        <CustomConfirmPopup
                                            title={t('license_application_submit_confirmation_text')}
                                            onConfirm={()=>handleLicenseSubmission()}
                                            icon={<CheckOutlined/>}
                                            okText={t('yes_text')}
                                            cancelText={t('no_text')}
                                            disabled={false}
                                            okType='default'
                                        >
                                            <PermissionChecker requiredPermissions={['machine-license-application-submit']}>
                                                <Button type={'primary'}>{
                                                    t('license_submit_button_text')
                                                }</Button>
                                            </PermissionChecker>
                                        </CustomConfirmPopup>: null
                                }
                            </Space>

                        </Col>
                    </Row>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col style={{lineHeight: '1.5rem'}}><h2>{machine?.name}</h2></Col>
                        <Col>
                            <Row style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Col span={10} style={{display:'flex'}}>
                                    <Col>
                                        <Col style={{lineHeight: '1.5rem'}}><h3>{t('machine_detail_data1')}</h3></Col>
                                        <Col>{t('machine_carnumber_field')} : {machine?.number}</Col>
                                        <Col>{t('machine_yearmade_field')} : {machine?.year_made}</Col>
                                        <Col>{t('machine_manufacturer_field')} : {machine?.manufacture}</Col>
                                        <Col>{t('machine_model_field')} : {machine?.model}</Col>
                                        <Col>{t('machine_registeredby_field')} : {machine?.created_by?.full_name}</Col>
                                        <Col style={{fontWeight: "bold", color:machine?.license_status === 0? '#377EF3' : machine?.license_status === 2 ? '#079327': '#DC000C'}}>{t('machine_licensestatus_field')} : {t(machine?.license_status_text)}</Col>

                                    </Col>
                                    <Divider type='vertical'/>
                                    <Col>
                                        <Col style={{lineHeight: '1.5rem'}}><h1>{t('machine_detail_data2')}</h1></Col>
                                        <Col>{t('machine_type_field')} : {t(machine?.machine_type_text)}</Col>
                                        <Col>{t('machine_identifier_field')} : {machine?.identifier}</Col>
                                        <Col>{t('machine_vrs_field')} : {machine?.vrs_id}</Col>
                                        <Col>{t('machine_assignto_field')} : {machine?.assigned_to_text}</Col>
                                    </Col>
                                </Col>
                                <Col span={12} style={{marginRight:'20px'}}>
                                    <MachinePosition />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {machine?.license_status === 2 && machine?.has_schedule ?
                        <Row style={{marginTop: '50px'}}>
                            <CustomTimeline data={machineSchedules} title={t('machine_rental_gantt_chart_title')}/>
                        </Row>:<></>}
                    {machine?.license_status === 2 && machine?.has_schedule ?<Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col span={12}>
                            <p style={{fontWeight: 'bold', fontSize: '800'}}>{t('machine_running_schedule_header_text')}</p>
                        </Col>
                        <Col>
                            <DataTable
                                locale={{emptyText: t('table_no_data_text')}}
                                sticky={true}
                                reload={runningassignmentTableReload}
                                url={`/api/v1/machines/constructor_machines/?machine=${machine?.id}&status_in=0,1`}
                                columns={runningColumns}
                                rowClassName={(row, index)=>getRowClassName(row, index)}
                                pagination={false}
                                hideHeader={true}
                            />
                        </Col>
                    </Row>:<></>}
                    <Divider style={{marginTop: '10px'}}/>
                    {machine?.machine_type === 1 ? <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col>
                            <p style={{fontWeight: 'bold', fontSize: '800'}}> {t('machine_bucketlist_title')} </p>
                        </Col>
                        <Col>
                            <DataTable
                                locale={{emptyText: t('table_no_data_text')}}
                                sticky={true}
                                reload={bucketDataReload}
                                pagination={true}
                                url={`/api/v1/machines/buckets/?machine=${machine?.id}`}
                                columns={bucket_columns}
                            />
                        </Col>
                    </Row> : null}
                    {machine?.machine_type === 1 ? <Divider style={{marginTop: '10px'}}/> : null}
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col>
                            <Row style={{justifyContent: 'space-between'}}>
                                <Col span={12}>
                                    <p style={{fontWeight: 'bold', fontSize: '800'}}>{t('registry_file_list_title')} </p>
                                </Col>
                                <Col span={12} style={{flexDirection:'row', display:'flex', justifyContent:'flex-end'}}>
                                    <Col span={4}>
                                        {/*<PermissionChecker requiredPermissions={['machine-registry-file-create']}>*/}
                                        {/*    <Button type={"default"} style={{background:'red', color:'white'}}*/}
                                        {/*        onClick={() => message.error('not_implemented_error_text')}*/}
                                        {/*    >*/}
                                        {/*        Switch*/}
                                        {/*    </Button>*/}
                                        {/*</PermissionChecker>*/}
                                    </Col>
                                    <Col span={4}>
                                        {/*<PermissionChecker requiredPermissions={['machine-registry-file-create']}>*/}
                                        {/*    <Button type={"default"} style={{background:'rgb(201, 143, 65)', color:'white'}}*/}
                                        {/*        onClick={() => setIsMachineRegistryReplaceModalVisible(true)}*/}
                                        {/*    >*/}
                                        {/*        Replace*/}
                                        {/*    </Button>*/}
                                        {/*</PermissionChecker>*/}
                                    </Col>
                                    <Col >
                                        {/*<p align='right' style={{fontWeight: 'bold', fontSize: '800'}}>*/}
                                        <PermissionChecker requiredPermissions={['machine-registry-file-create']}>
                                            <Button
                                                className={'bordered-default-button'}
                                                onClick={() => setIsMachineRegistryModalVisible(true)}
                                                icon={<UploadOutlined />}
                                                // type={"default"}
                                            >
                                                {t('calibration_profile_upload_button_text')}
                                            </Button>
                                        </PermissionChecker>
                                        {/*</p>*/}
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <DataTable
                                locale={{emptyText: t('table_no_data_text')}}
                                sticky={true}
                                reload={registryFileReload}
                                pagination={true}
                                url={`/api/v1/machines/machine_registry_files/?machine=${machine?.id}`}
                                columns={fileColumns}/>
                        </Col>
                    </Row>
                    {machine?.status === 1 ?<><Divider style={{marginTop: '10px'}}/>
                        <Row style={{display: 'flex', flexDirection: 'column'}}>
                            <Col>
                                <p style={{fontWeight: 'bold', fontSize: '800'}}> {t('lease_historylist_title')} </p>
                            </Col>
                            <Col>
                                <DataTable
                                    locale={{emptyText: t('table_no_data_text')}}
                                    sticky={true}
                                    reload={assignmentTableReload}
                                    url={`/api/v1/machines/constructor_machines/?machine=${machine?.id}`}
                                    columns={columns}
                                    pagination={true}
                                    searchFields={leaseHistorySearchFields}
                                />
                            </Col>
                        </Row></>:<></>}
                </div>
            </>

        )
    }
    else{
        return null;
    }
}

export default MachineDetailPage;
