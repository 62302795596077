import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import {useNavigate} from "react-router-dom";
import http from "../../http";
import ConstructorAddPage from "./ConstructorAddPage";
import {Button, Col, message, Row, Tooltip, Typography} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";
import EditPencilIcon from "../../components/CustomIcons/EditPencilIcon";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";


const ConstructorListPage = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [reload, setReload] = useState(false);
    const [edit, setEdit] = useState(false);
    const [item, setItem] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleOpenModal = (item, edit) => {
        if(edit){
            if(item.status===0 && !item.with_code){
                setEdit(true);
                setItem(item);
                setShowModal(true);
            }
            else{
                message.error(t('constructor_edit_vallidation_text'))
            }
        }
        else {
            setEdit(false);
            setItem(null);
            setShowModal(true);

        }
    };

    const handleCloseModal = () => {
        setEdit(false);
        setItem(null);
        setShowModal(false);
    };
    const handleSuccessAdd = () =>{
        setEdit(false);
        setItem(null);
        setShowModal(false);
        setReload(!reload);
    }

    const handleDeleteClick = (item)=>{
        if(item.status===0){
            http.delete(`/api/v1/constructors/available_constructors/${item.id}/delete/`).then((resp)=>{
                setReload(!reload);
            })
        }
        else if(item.status===3){
            message.error("constructor_delete_error_during_pending_mediation")
        }
    }
    const buildPageHeader = (data) =>{
        return null;
    }
    const buildCardHeader = (item)=>{
        return <Row className={'cardHeader'} style={{alignItems:'center'}} key={'cardHeader'}>
            <Tooltip title={item? item[config.cardTitleField]: ''} placement={'bottomLeft'}>
                <Col key={'cardTitle'} span={16} style={{whiteSpace: 'nowrap', fontWeight: 'bold', fontSize:'20px', overflow:'hidden', textOverflow:'ellipsis'}}>{item? item[config.cardTitleField]: ''}</Col>
            </Tooltip>
            <Col key={'cardButtons'} span={8} style={{display:'flex', flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>
                 {item.status === 0 && !item.with_code ?
                     <PermissionChecker requiredPermissions={config.editPermission || []}>
                    <Button icon={<EditPencilIcon />} disabled={config.hideEdit} style={{border: 'none', background:'none'}} onClick={()=>handleOpenModal(item, true)}/>
                </PermissionChecker> : <div></div>}
                {item.status !== 3 ?
                <CustomConfirmPopup
                    title={config.deleteTitle}
                    onConfirm={()=>handleDeleteClick(item)}
                    icon={<DeleteOutlined style={{color: '#377EF3'}}/>}
                    okText={t('yes_text')}
                    cancelText={t('no_text')}
                    okType='default'
                >
                    <PermissionChecker requiredPermissions={config.deletePermission || []}>
                        <Typography.Link style={{color: config.disableDelete ? 'grey': '#377EF3'}}><DeleteOutlined style={{color: '#377EF3'}}/>
                        </Typography.Link>
                    </PermissionChecker>
                </CustomConfirmPopup> : <div></div>}
            </Col>
        </Row>;
    }

    const buildCardBody = (data)=>{
        return null;
    }

    const buildCardFooter=(data)=>{
        return null;
    }

    const renderFooterFields=(data)=>{
        return [<label style={{color: data.status === 1 ? '#079327': '#DC000C'}}>{t(data.status_text)}</label>]
    }

    const buildFooterFields=(data)=>{
        return renderFooterFields(data)
    }

    const config = {
        url: '/api/v1/constructors/available_constructors/',
        pageTitle: t('constructor_list_title'),
        pageSubtitle: t('constructor_list_subtitle'),
        deleteTitle: t('constructor_delete_confirmation_text'),
        searchPlaceHolder: t('listpage_search_text') +'...',
        cardTitleField: 'full_name',
        cardDetailButtonText: t('listpage_detail_button_text'),
        cardDeleteButtonText: 'Delete Selected',
        cardBodyFields: {'email': t('constructor_addpage_email'), 'contact_no': t('constructor_addpage_contactno'), 'contact_person_name': t('constructor_contact_person_field')},
        cardFooterFields: [],
        addPermission: ['available-constructor-create'],
        editPermission: ['available-constructor-edit'],
        deletePermission: ['available-constructor-cancel-mediation', 'available-constructor-delete'],
        viewPermission: ['available-constructor-detail'],
        orderingFields: [
            {id: '-company_name', name: t('constructor_orderby_field1')},
            {id: 'company_name', name: t('constructor_orderby_field2')},
            {id: '-created', name: t('constructor_orderby_field3')},
            {id: 'created', name: t('constructor_orderby_field4')},
        ],
        'buildPageHeader': buildPageHeader,
        'buildCardHeader': buildCardHeader,
        'buildCardBody': buildCardBody,
        'buildCardFooter': buildCardFooter,
        'buildFooterFields': buildFooterFields,
    }

    const handleDetailClick=(item)=>{
        navigate('/constructor/detail/', { replace: false, state: {constructor: item} })
    }
    return (
        <>
            <ListPage
                config={config}
                onAddClick={handleOpenModal}
                onEditClick={(item)=>handleOpenModal(item, true)}
                onDeleteClick={(item)=>handleDeleteClick(item)}
                onDetailClick={(item)=>handleDetailClick(item)}
                reload={reload}
            />
            <ConstructorAddPage
                visible={showModal}
                onClose={handleCloseModal}
                onSuccessSubmit={handleSuccessAdd}
                edit={edit}
                editItem={item}
            />
        </>
    );
};

export default ConstructorListPage;
