import AddPageModal from "../../components/AddPage/AddPage";
import {useState} from "react";
import http from "../../http";
import {message} from "antd";


const BucketToolConfigAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem, modalSize, reload})=>{
    const [initialValues, setInitialValues] = useState({});
    const config = {
        addUrl: '/api/v1/bucket_tool_config/add/',
        editUrl: edit ? `/api/v1/bucket_tool_config/${editItem?.id}/edit/` : '',
        dataUrl: edit? `/api/v1/bucket_tool_config/${editItem?.id}/` : '',
        pageTitle: 'Tool Config Add Page',
        colNo: 2,
        cancelButtonText: 'Cancel',
        saveButtonText: !edit ? 'Save' : 'Edit',
        addSuccessMessage: !edit ? 'Tool Config Added Successfully' : 'Tool Config Updated Successfully',
        modalSize: modalSize || 800,
        fields: [
            {
                name: 'file',
                label: 'Tool Config',
                placeholder: 'Tool Config File',
                isRequired: true,
                requiredMessage: 'Tool Config File Required',
                type: 'file',
                colNo: 1,
                order: 1
            }
        ]
    }
    const handleOnClose =()=>{
        setInitialValues({});
        if(onClose){
            onClose()
        }
    }

    const handleSuccessSubmit=()=>{
        setInitialValues({});
        if(onSuccessSubmit){
            onSuccessSubmit()
        }
    }
    const onFinish=(values, handleError, form)=>{
        const formData = new FormData();
        const requestConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        for (let name in values) {
            const value = values[name];
            if (value instanceof File ) {
                formData.append(name, value, value.name);
            }
            // Append other values to the FormData object
            else if(value !== 0 &&(!value || value === 'undefined' || value === 'null')) {
                formData.append(name, '');
            }
            else{
                formData.append(name, value);
            }
        }
        if (!edit) {
            http.post(config.addUrl, formData, requestConfig).then((resp) => {
                if (resp.status === 201 || resp.status === 200) {
                    message.success(config.addSuccessMessage || resp.data.message || 'Added Successfully');
                    if (onSuccessSubmit) {
                        handleSuccessSubmit()
                        form.resetFields();
                    } else if (onClose) {
                        handleOnClose()
                        form.resetFields();
                    }
                }
            }).catch((error) => {
                handleError(error);
                if (onErrorSubmit) {
                    onErrorSubmit()
                }
            })
        }
    }

    return <AddPageModal onFinish={onFinish} config={config} visible={visible} onClose={handleOnClose} onSuccessSubmit={handleSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues}/>

}

export default BucketToolConfigAddPage;