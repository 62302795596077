import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";
import {useTranslation} from "react-i18next";

const MachineRegistryFileAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem, machine, reload, replace})=>{
    const [initialValues, setInitialValues] = useState({machine: machine.id});
    const defaultValues = {machine: machine.id};
    const { t } = useTranslation();
    const config = {
        addUrl: '/api/v1/machines/machine_registry_files/add/',
        editUrl: edit ? `/api/v1/machines/machine_registry_files/${editItem?.id}/edit/` : '',
        dataUrl: edit ? `/api/v1/machines/machine_registry_files/${editItem?.id}/` : '',
        pageTitle: replace ? "Replace Profile": t('machine_profile_addpage_title'),
        colNo: 2,
        cancelButtonText: t('addpage_cancel_button_text'),
        saveButtonText: !edit ? t('addpage_save_button_text') : t('addpage_edit_button_text'),
        addSuccessMessage: t('machine_profile_upload_successful_message'),
        modalSize: 500,
        fields: [
            {
                name: 'replace_profile',
                label: t('replace_profile_file_field'),
                placeholder: t('replace_profile_file_field_placeholder'),
                isRequired: true,
                requiredMessage: t('replace_profile_file_field_required_text'),
                type: 'select',
                extraProps: {
                    url: `/api/v1/machines/machine_registry_files/combo/?machine=${machine.id}&is_obsolete=0`,
                },
                colNo: 1,
                order: 1,
                hidden: machine.machine_type === 2 || !replace
            },
            {
                name: 'file',
                label: t('profile_file_field'),
                placeholder: t('profile_file_field_placeholder'),
                isRequired: true,
                requiredMessage: t('profile_file_field_required_text'),
                type: 'file',
                colNo: 1,
                order: 2,
                hidden: machine.machine_type === 2
            },
            {
                name: 'name',
                label: t('profile_name_field'),
                placeholder: t('profile_name_field_placeholder'),
                isRequired: true,
                requiredMessage: t('profile_name_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 1,
                hidden: machine.machine_type === 1
            },
            {
                name: 'roller_Width',
                label: t('profile_roller_width_field'),
                placeholder: t('profile_roller_width_field_placeholder'),
                isRequired: true,
                requiredMessage: t('profile_roller_width_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 1,
                hidden: machine.machine_type === 1
            },
            {
                name: 'antenna_height',
                label: t('profile_antenna_height_field'),
                placeholder: t('profile_antenna_height_field_placeholder'),
                isRequired: true,
                requiredMessage: t('profile_antenna_height_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 2,
                hidden: machine.machine_type === 1
            },
            {
                name: 'machine_length',
                label: t('profile_machine_length_field'),
                placeholder: t('profile_machine_length_field_placeholder'),
                isRequired: true,
                requiredMessage: t('profile_machine_length_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 3,
                hidden: machine.machine_type === 1
            },
            {
                name: 'machine_width',
                label: t('profile_machine_width_field'),
                placeholder: t('profile_machine_width_field_placeholder'),
                isRequired: true,
                requiredMessage: t('profile_machine_width_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 4,
                hidden: machine.machine_type === 1
            },
        ]
    }

    const onFinish=(values, handleError, form)=>{
        const id = machine.id
        const newValues = {...values, machine: id}
        const formData = new FormData();
        const requestConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        for (let name in newValues) {
            const value = newValues[name];
            if (value instanceof File ) {
                formData.append(name, value, value.name);
            }
            // Append other values to the FormData object
            else if(!value || value === 'undefined' || value === 'null') {
                formData.append(name, '');
            }
            else{
                formData.append(name, value);
            }

        }
        if (!edit) {
            http.post(config.addUrl, formData, requestConfig).then((resp) => {
                if (resp.status === 201) {
                    message.success(config.addSuccessMessage || resp.data.message || 'Added Successfully');
                    if (onSuccessSubmit) {
                        onSuccessSubmit()
                    } else if (onClose) {
                        onClose()
                    }
                }
            }).catch((error) => {
                handleError(error);
                if (onErrorSubmit) {
                    onErrorSubmit()
                }
            })
        }
        else{
            http.patch(config.editUrl, formData).then((resp) => {
                if (resp.status === 200) {
                    message.success(config.addSuccessMessage || resp.data.message || 'Added Successfully');
                    if (onSuccessSubmit) {
                        onSuccessSubmit()
                    } else if (onClose) {
                        onClose()
                    }
                }
            }).catch((error) => {
                handleError(error);
                if (onErrorSubmit) {
                    onErrorSubmit()
                }
            })
        }
    }

    useEffect(()=>{
        setInitialValues({});
        if(editItem){
            let dataUrl = `/api/v1/machines/machine_registry_files/${editItem?.id}/`;
            http.get(dataUrl).then((resp)=>{
                setInitialValues(resp.data);
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])

    const getWarningMessage=()=>{
        if(machine?.has_running_contract){
            return t('machine_dc_profile_change_warning')
        }
        return null;
    }

    return <AddPageModal
        onFinish={onFinish}
        config={config}
        visible={visible}
        onClose={onClose}
        onSuccessSubmit={onSuccessSubmit}
        onErrorSubmit={onErrorSubmit}
        edit={edit}
        initialValues={initialValues}
        defaultValues={defaultValues}
        warningMessage={getWarningMessage()}
    />

}

export default MachineRegistryFileAddPage;
