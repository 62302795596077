import {Divider, Row, Col, Space, Table} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import http from "../../http";
import {useTranslation} from "react-i18next";
import DataTable from "../../components/DataTable/DataTable";


const VRSServerDetailPage=(props)=>{
    const navigate = useNavigate();
    const location = useLocation();
    const {state} = location;
    const [loading, setLoading] = useState(false);
    const [vrs, setConstructor] = useState(state?.vrs);
    const [isValidState, setValidState] = useState(!!state?.vrs);
    const { t } = useTranslation();
    const historyColumns = [
        {
            title: t('vrs_usage_history_list_col1'),
            dataIndex: 'machine_name',
            key: 'machineName',
        },
        {
            title: t('vrs_usage_history_list_col2'),
            dataIndex: 'start_date',
            key: 'startDate',
        },
        {
            title: t('vrs_usage_history_list_col3'),
            dataIndex: 'end_date',
            key: 'endDate',
        },
        {
            title: t('vrs_usage_history_list_col4'),
            dataIndex: 'status_text',
            key: 'statusText',
            render: (text)=>(
                <div style={{color: text === 'active_label' ? '#079327': '#DC000C'}}>{t(text)}</div>
            )
        },
    ]
    const fetchConstructorData=()=>{
        http.get(`/api/v1/machines/vrs/${vrs?.id}/`).then((resp)=>{
            setLoading(false);
            setConstructor(resp.data);
        }).catch((err)=>{
            setLoading(false);
        })
    }

    useEffect(()=>{
        if(!state){
            navigate('/content_not_found',{replace: true})
        }
        if(isValidState) {
            fetchConstructorData()
        }
    }, [state])

    const buildBreadcrumbs=()=>{
        let breadcrumbs = [];
        if(state.breadcrumbs){
            state.breadcrumbs.map((item, index)=>{
                breadcrumbs.push(
                    <><Link to={item.to} state={item.state} key={index}>{item.title}</Link> > </>
                )
            })
            breadcrumbs.push(<>{vrs.name}</>)
            return breadcrumbs;
        }
        return <><Link to={'/vrs_servers'}>{t('vrs_server_listpage_title')}  </Link> >  {vrs.name}</>;
    }
    if(isValidState) {
        return (
            <>
                <div style={{overflowY: 'auto', overflowX: 'hidden', width: '100%', height: '100%'}}>
                    <Row style={{height: '5%', color: "black", fontWeight: 'bold', justifyContent: 'space-between'}}>
                        <Col>
                            <Space>{buildBreadcrumbs()}</Space>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col style={{lineHeight: '1.5rem'}}><h1>{vrs.name}</h1></Col>
                        <Col>{t('vrs_server_serverid_field')} : {vrs.server_id}</Col>
                        <Col>{t('vrs_server_provider_field')} : {vrs.provider}</Col>
                        <Col>{t('vrs_server_url_field')} : {vrs.url}</Col>
                        <Col>{t('vrs_server_port_field')} : {vrs.port}</Col>
                        <Col>{t('vrs_server_password_field')} : {vrs.password}</Col>
                        <Col>{t('vrs_server_mountpoint_field')} : {vrs.mountpoint}</Col>
                    </Row>
                    <Divider/>
                    <Divider style={{marginTop: '10px'}}/>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col>
                            <p style={{fontWeight: "bold", fontSize: "800"}}> {t('vrs_usage_history_list_title')} </p>
                        </Col>
                        <Col>
                            <DataTable
                                locale={{emptyText: t('table_no_data_text')}}
                                sticky={true}
                                loading={loading}
                                data={vrs.histories}
                                columns={historyColumns}
                                pagination={true}
                            />
                        </Col>
                    </Row>
                </div>
            </>

        )
    }
    else{
        return null;
    }
}

export default VRSServerDetailPage;
