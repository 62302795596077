import dayjs from "dayjs";

export const getReadableTimeFromTimeStamp = (value, format='YYYY-MM-DD HH:mm:ss')=>{
    const date = dayjs(value);

    // Format the date components as a human-readable string
    return date.format(format);
}

export const getReadableDateFromTimeStamp = (value, format='YYYY-MM-DD HH:mm')=>{
    const date = dayjs(value);

    // Format the date components as a human-readable string
    return date.format(format);
}

export const getNotificationDateFromTimeStamp = (value, format='YYYY.MM.DD')=>{
    const date = dayjs(value);

    // Format the date components as a human-readable string
    return date.format(format);
}