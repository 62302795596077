import {Divider, Row, Col, Space, Table, Typography, Modal, Button, message} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import MachineLeaseStatusListPage from "./MachineLeaseStatusListPage";
import {CheckOutlined, DeleteOutlined, EyeOutlined} from '@ant-design/icons';
import http from "../../http";
import ConstructorMachineView from "../ConstructorMachines/ConstructorMachineView"
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";
import DataTable from "../../components/DataTable/DataTable";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";

const ConstructorDetailPage=(props)=>{
    const navigate = useNavigate();
    const location = useLocation();
    const {state} = location;
    const [loading, setLoading] = useState(false);
    const [constructor, setConstructor] = useState(state?.constructor);
    const [viewModalVisible, setViewModalVisible] = useState(false);
    const [constructorMachine, setConstructorMachine] = useState(null);
    const [isValidState, setValidState] = useState(!!state?.constructor);
    const { t } = useTranslation();

    const columns = [
        {
            title: t('lease_history_col1'),
            dataIndex: 'contract_code',
            key: 'contract_code',
        },
        {
            title: t('lease_history_col10'),
            dataIndex: 'reference_code',
            key: 'reference_code',
        },
        {
            title: t('lease_history_col2'),
            dataIndex: 'start_date',
            key: 'startDate',
        },
        {
            title: t('lease_history_col3'),
            dataIndex: 'end_date',
            key: 'endDate',
        },
        {
            title: t('lease_history_col4'),
            dataIndex: 'machine_name',
            key: 'machineName',
        },
        {
            title: t('lease_history_col5'),
            dataIndex: 'machine_data',
            key: 'machine_type',
            render: (text)=>(
                <div>{t(text.machine_type_text)}</div>
            )
        },
        {
            title: t('lease_history_col6'),
            dataIndex: 'machine_data',
            key: 'machineId',
            render: (text)=>(
                <div>{text.identifier}</div>
            )
        },
        {
            title: t('lease_history_col7'),
            dataIndex: 'status_text',
            render: (text)=>(
                <div>{t(text)}</div>
            ),
            key: 'statusText',
        },
        {
            title: t('lease_history_col9'),
            key: 'action',
            fixed:'right',
            render: (record) => (
                <Space size="middle">
                    <PermissionChecker requiredPermissions={['constructor-machine-detail']}>
                        <Typography.Link onClick={()=>{setConstructorMachine(record); setViewModalVisible(true)}}><EyeOutlined/></Typography.Link>
                    </PermissionChecker>
                </Space>
            )
        },
    ]
    const fetchConstructorData=()=>{
        http.get(`/api/v1/constructors/available_constructors/${constructor?.id}/`).then((resp)=>{
            setLoading(false);
            setConstructor(resp.data);
        }).catch((err)=>{
            setLoading(false);
        })
    }

    useEffect(()=>{
        if(!state){
            navigate('/content_not_found',{replace: true})
        }
        if(isValidState) {
            fetchConstructorData()
        }
        // fetchDefaultData()
    }, [state])

    const buildBreadcrumbs=()=>{
        let breadcrumbs = [];
        if(state.breadcrumbs){
            state.breadcrumbs.map((item, index)=>{
                breadcrumbs.push(
                    <><Link to={item.to} state={item.state} key={index}>{item.title}</Link> > </>
                )
            })
            breadcrumbs.push(<>{constructor.full_name}</>)
            return breadcrumbs;
        }
        return <><Link to={'/constructors'}>{t('constructor_list_title')}  </Link> >  {constructor.full_name}</>;
    }

    const handleViewModalCancel=()=>{
        setViewModalVisible(false)
    }
    const handleConstructorRegistration=(data)=>{
        http.post(`/api/v1/constructors/available_constructors/${data.id}/submit/`).then((resp)=>{
            if(resp.status === 200){
                message.success(t('constructor_mediation_submit_succesful_message'))
                fetchConstructorData()
            }
            else{
                message.error(t('mediation_request_submit_failed_text'))
            }
        }).catch((err)=>{
            message.error(t('mediation_request_submit_failed_text'))
        })
    }
    const handleDeleteClick = (item)=>{
            http.post(`/api/v1/constructors/available_constructors/${item.id}/cancel_mediation/`).then((resp)=>{
                if(resp.status === 200) {
                    message.success(t('mediation_cancel_succesful_message'))
                    fetchConstructorData()
                }
                else if(resp.status === 400){
                    message.error(t(resp.data?.error?.split(':')[0] + ' '+ resp.data?.error?.split(':')[1]))
                }
                else{
                    message.success(t('mediation_cancel_failed_message'))
                    fetchConstructorData()
                }
            }).catch((err)=>{
                message.success(t(err.response.data.error.split(':')[0], {contract_code: err.response.data.error.split(':')[1]}))
                fetchConstructorData()
            })
    }
    if(isValidState) {
        return (
            <>
                <Modal
                    open={viewModalVisible}
                    title={t('contract_detail_view_title', {contract_code: constructorMachine?.contract_code})}
                    onCancel={handleViewModalCancel}
                    onOk={handleViewModalCancel}
                    maskClosable={false}
                    width={800}
                    cancelText={t('modalCancelText')}
                    okText={t('modal_okText')}
                >
                    <ConstructorMachineView constructorMachine={constructorMachine}/>
                </Modal>
                <div style={{overflowY: 'auto', overflowX: 'hidden', width: '100%', height: '100%'}}>
                    <Row style={{height: '5%', color: "black", fontWeight: 'bold', justifyContent: 'space-between'}}>
                        <Col>
                            <Space>{buildBreadcrumbs()}</Space>
                        </Col>
                        <Col>
                            <Space>
                                {constructor.status === 1 ?
                                    <CustomConfirmPopup
                                        title={t('mediation_cancel_confirm_text')}
                                        onConfirm={()=>handleDeleteClick(constructor)}
                                        icon={<DeleteOutlined style={{color: '#377EF3'}}/>}
                                        okText={t('yes_text')}
                                        cancelText={t('no_text')}
                                        okType='default'
                                    >
                                        <PermissionChecker requiredPermissions={['available-constructor-cancel-mediation']}>
                                            <Button type={'primary'} className={"button-red-filled"}>{t('constructor_cancel_mediation_button_text')}</Button>
                                        </PermissionChecker>
                                    </CustomConfirmPopup>: constructor.status === 0 ?
                                        <CustomConfirmPopup
                                            title={t('mediation_submit_confirmation_text')}
                                            onConfirm={()=>handleConstructorRegistration(constructor)}
                                            icon={<CheckOutlined/>}
                                            okText={t('yes_text')}
                                            cancelText={t('no_text')}
                                            disabled={false}
                                            okType='default'
                                        >
                                            <PermissionChecker requiredPermissions={['available-constructor-submit']}>
                                                <Button type={'primary'} className={'primary-button'}>{t('constructor_mediation_request_button_text')}</Button>
                                            </PermissionChecker>
                                        </CustomConfirmPopup>: null
                                }
                            </Space>

                        </Col>
                    </Row>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col style={{lineHeight: '1.5rem'}}><h1>{constructor.full_name}</h1></Col>
                        <Col>{t('constructor_company_name_field')} : {constructor.company_name}</Col>
                        <Col>{t('constructor_addpage_email')} : {constructor.email}</Col>
                        <Col>{t('constructor_addpage_contactno')} : {constructor.contact_no}</Col>
                        <Col>{t('constructor_addpage_address1')} : {constructor.address_1}</Col>
                    </Row>
                    <Divider/>
                    <Row style={{overflow: "hidden", height: '60%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <MachineLeaseStatusListPage pageSize={3} constructor={constructor}/>
                        </div>
                    </Row>
                    <Divider style={{marginTop: '10px'}}/>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col>
                            <p style={{fontWeight: "bold", fontSize: "800"}}> {t('lease_historylist_title')} </p>
                        </Col>
                        <Col>
                            <DataTable
                                pagination={true}
                                locale={{emptyText: t('table_no_data_text')}}
                                sticky={true}
                                loading={loading}
                                url={`/api/v1/machines/constructor_machines/?constructor=${constructor.id}`}
                                columns={columns}
                            />
                        </Col>
                    </Row>
                </div>
            </>

        )
    }
    else{
        return null;
    }
}

export default ConstructorDetailPage;
